import styles from './Search.module.scss';
import disabledStyles from './DisabledSearchBox.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { mousePointerClassName } from 'components/detection';
import { mobileSafariMediaQuery } from 'utils/detections';
import Search from './Search';
import { getBorderStyleCssValue } from 'components/theme';

const SearchBarBlock = ({ id, model, isDesignerMode }) => {
  const options = useMemo(() => ({
    placeholder: model.box_Placeholder,
    boxThemeFontSize: model.box_ThemeFontSize,
    buttonLayout: model.button_Layout,
    buttonThemeFontSize: model.button_ThemeFontSize,
    showThumbnails: model.suggestions_ShowThumbnails,
    suggestionsThemeFontSize: model.suggestions_ThemeFontSize,
  }), [model]);
  const searchId = `Search_${id}`;

  return (
    <>
      <Helmet>
        <style>{`
          ${model.box_PlaceholderFontColor ? `
            #${searchId} .${styles.input}::placeholder {
              color: ${model.box_PlaceholderFontColor};
            }
          `: ''}
          #${searchId} .${styles.container} {
            border-style: ${getBorderStyleCssValue(model.box_BorderStyle)};
            ${model.box_BorderColor ? `border-color: ${model.box_BorderColor};` : ''}
            ${model.box_BorderWidth ? `border-width: ${model.box_BorderWidth};` : ''}
            ${model.box_CornerRadius ? `border-radius: ${model.box_CornerRadius};` : ''}
          }
          ${model.box_FocusBorderColor ? `
            #${searchId} .${styles.container}:not(.${styles.disabled}):focus-within {
              border-color: ${model.box_FocusBorderColor};
            }
          ` : ''}
          ${model.box_FontFamily || model.box_FontColor || model.box_BackgroundColor ? `
            #${searchId} .${styles.input}, 
            #${searchId} .${disabledStyles.fakeInput} {
              ${model.box_FontFamily ? `font-family: ${model.box_FontFamily}, sans-serif;` : ''}
              ${model.box_FontColor ? `color: ${model.box_FontColor};` : ''}
              ${model.box_BackgroundColor ? `background-color: ${model.box_BackgroundColor};` : ''}
            }
          ` : ''}
          ${model.box_FocusBackgroundColor ? `#${searchId} .${styles.input}:focus {
              background-color: ${model.box_FocusBackgroundColor};
            }
          ` : ''}
          ${model.button_FontFamily || model.button_FontColor || model.button_BackgroundColor || model.button_CornerRadius ? `
            #${searchId} .${styles.submit} {
              ${model.button_FontFamily ? `font-family: ${model.button_FontFamily}, sans-serif;` : ''}
              ${model.button_FontColor ? `color: ${model.button_FontColor};` : ''}
              ${model.button_BackgroundColor ? `background-color: ${model.button_BackgroundColor};` : ''}
              ${model.button_CornerRadius ? `border-radius: ${model.button_CornerRadius};` : ''}
            }
          ` : ''}
          ${model.button_DisabledBackgroundColor ? `
            #${searchId} .${styles.submit}:disabled {
              background-color: ${model.button_DisabledBackgroundColor};
            }
          ` : ''}
          ${model.suggestions_FontColor ? `
            #${searchId} .${styles.suggestItem} {
              color: ${model.suggestions_FontColor};
            }
          ` : ''}
          #${searchId} .${styles.suggestion} {
            background-color: ${model.suggestions_BackgroundColor || '#FFFFFF'};
          }
          #${searchId} .${styles.suggestionsContainer},
          #${searchId} .${styles.suggestion} {
            border-color: ${model.suggestions_BorderColor || '#E6E6E6'};
          }
          #${searchId} .${styles.highlight} {
            color: ${model.suggestions_HighlightedTextFontColor || '#333333'};
          }
          ${model.button_HoverFontColor || model.button_HoverBackgroundColor ? `
            html.${mousePointerClassName} #${searchId} .${styles.submit}:not(:disabled):hover {
              ${model.button_HoverFontColor ? `color: ${model.button_HoverFontColor};` : ''}
              ${model.button_HoverBackgroundColor ? `background-color: ${model.button_HoverBackgroundColor};` : ''}
            }
          ` : ''}
          ${mobileSafariMediaQuery && model.button_HoverFontColor ? `
            #${searchId} .${styles.submit}:not(:disabled):hover {
              -webkit-tap-highlight-color: ${model.button_HoverFontColor};
            }
          `: ''}
          ${model.suggestions_ItemHoverFontColor ? `
            html.${mousePointerClassName} #${searchId} .${styles.suggestionHighlighted} .${styles.suggestItem} {
              color: ${model.suggestions_ItemHoverFontColor};
            }
            ${mobileSafariMediaQuery ? `
              #${searchId} .${styles.suggestionHighlighted} {
                -webkit-tap-highlight-color: ${model.suggestions_ItemHoverFontColor};
              }
            `: ''}
          ` : ''}
          #${searchId} .${styles.suggestionHighlighted} {
            background-color: ${model.suggestions_ItemHoverBackgroundColor || '#F7F7F7'}!important;
          }
        `}</style>
      </Helmet>
      <Search id={searchId} isDesignerMode={isDesignerMode} options={options} />
    </>
  );
};

SearchBarBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    box_Placeholder: PropTypes.string,
    box_PlaceholderFontColor: PropTypes.string,
    box_BackgroundColor: PropTypes.string,
    box_FocusBackgroundColor: PropTypes.string,
    box_BorderColor: PropTypes.string,
    box_FocusBorderColor: PropTypes.string,
    box_BorderStyle: PropTypes.number.isRequired,
    box_BorderWidth: PropTypes.string.isRequired,
    box_CornerRadius: PropTypes.string.isRequired,
    box_FontFamily: PropTypes.string,
    box_ThemeFontSize: PropTypes.string.isRequired,
    box_FontColor: PropTypes.string,
    button_Layout: PropTypes.number.isRequired,
    button_FontFamily: PropTypes.string,
    button_ThemeFontSize: PropTypes.string.isRequired,
    button_FontColor: PropTypes.string,
    button_HoverFontColor: PropTypes.string,
    button_BackgroundColor: PropTypes.string,
    button_HoverBackgroundColor: PropTypes.string,
    button_DisabledBackgroundColor: PropTypes.string,
    button_CornerRadius: PropTypes.string.isRequired,
    suggestions_ShowThumbnails: PropTypes.bool.isRequired,
    suggestions_ThemeFontSize: PropTypes.string.isRequired,
    suggestions_FontColor: PropTypes.string,
    suggestions_BackgroundColor: PropTypes.string,
    suggestions_BorderColor: PropTypes.string,
    suggestions_ItemHoverFontColor: PropTypes.string,
    suggestions_ItemHoverBackgroundColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default memo(SearchBarBlock);
