import { memo } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'utils/render';
import Heading from './Heading';

const HeadingBlock = ({ model, createInlineHtmlEditor }) => {
  if (!model.content)
    return null;

  return (
    <Heading headingStyle={model.headingStyle} color={model.color}>
      {createInlineHtmlEditor
        ? createInlineHtmlEditor(model, 'content', { textOnlyMode: true })
        : renderHTML(model.content)}
    </Heading>
  );
};

HeadingBlock.propTypes = {
  model: PropTypes.shape({
    content: PropTypes.string,
    headingStyle: PropTypes.string.isRequired,
    color: PropTypes.string,
  }).isRequired,
  createInlineHtmlEditor: PropTypes.func,
};

export default memo(HeadingBlock);