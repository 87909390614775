exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageTiles_root img{max-width:100%}.ImageTiles_root .html-wrapper{margin:0}.ImageTiles_root .lazy-load-image-background{max-width:100%}.ImageTiles_column{margin-bottom:1.5em}@media (min-width:var(--breakpoints_Medium,960)px),print{.ImageTiles_root{padding-top:1em;padding-bottom:1em}.ImageTiles_column{margin-bottom:0}}", ""]);

// exports
exports.locals = {
	"root": "ImageTiles_root",
	"column": "ImageTiles_column"
};