import styles from './ImageTiles.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import ImageBlock from './ImageBlock';
import HtmlBlock from 'components/objects/htmlBlock/HtmlBlock';

const ImageTiles = ({ model }) => {
  if (!model || !model.tiles || !model.tiles.length)
    return null;

  return (
    <Row className={styles.root}>
      {model.tiles
        .filter(({ imagePath, description }) => {
          return !!imagePath || !!description;
        })
        .map(({ description, ...imgBlockProps }, index) => (
          <Col key={index} xs={12} md className={styles.column}>
            <ImageBlock {...imgBlockProps} />
            {description && <HtmlBlock model={{ content: description }} />}
          </Col>
        ))
      }
    </Row>
  );
};

ImageTiles.propTypes = {
  model: PropTypes.shape({
    tiles: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: PropTypes.string,
        altText: PropTypes.string,
        link: PropTypes.object,
        description: PropTypes.string,
      }),
    ),
  }),
};

export default memo(ImageTiles);