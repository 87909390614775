import styles from './Search.module.scss';
import PropTypes from 'prop-types';
import { getThemeFontSizeClassName } from 'components/theme';

const SuggestionItem = ({ suggestion, options }) => {
  let categories;
  if (suggestion.categoriesPaths?.length)
    categories = suggestion.categoriesPaths[0].categories;

  return (
    <span className={`${styles.suggestItem} ${getThemeFontSizeClassName(options.suggestionsThemeFontSize)}`}>
      {options.showThumbnails && (
        <span className={styles.img}>
          <img src={suggestion.imageUrl} alt={suggestion.title} />
        </span>
      )}
      <span className={styles.text}>
        <span className={styles.titleId}>
          {suggestion.highlightedText}
        </span>
        <span className={styles.category}>
          {categories?.length ? categories[categories.length - 1].name : null}
        </span>
      </span>
    </span>
  );
};

SuggestionItem.propTypes = {
  suggestion: PropTypes.shape({
    imageUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    highlightedText: PropTypes.node.isRequired,
    categoriesPaths: PropTypes.arrayOf(
      PropTypes.shape({
        categories: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
        })),
      }),
    ),
  }).isRequired,
  options: PropTypes.shape({
    showThumbnails: PropTypes.bool.isRequired,
    suggestionsThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
};

export default SuggestionItem;
