import { memo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';
import { Link } from 'components/primitives/links';

const ImageBlock = ({ imagePath, altText = '', link }) => {
  if (!imagePath)
    return null;

  const responsiveImage = <ResponsiveLazyImage src={imagePath} alt={altText} title={altText} />;

  return (
    <div>
      {link && link.url
        ? <Link {...link} children={responsiveImage} />
        : responsiveImage
      }
    </div>
  );
};

ImageBlock.propTypes = {
  imagePath: PropTypes.string,
  altText: PropTypes.string,
  link: PropTypes.object,
};

export default memo(ImageBlock);